:root {
  --nu-background-light: #ededed;
  --nu-background-primary: #0e100f;
  --nu-primary-color-dark: #98c41e;
  --nu-primary-color: #C6FF28;
  --nu-primary-primary-light: #deff85;
}

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  background-color: var(--nu-background-primary);
}

::-webkit-scrollbar {
  scrollbar-color: red;
}

.title {
  font-size: 3rem;
}

.primaryColor {
  color: var(--nu-primary-color);
}

.rotated-e {
  transform: rotate(45deg);
}

.invertU {
  transform: scale(-1);
  display: inline-block;
}

.nu-text-secondary {
  color: rgb(176, 176, 176);
}

.nu-icon {
  transition: .2s;
  cursor: pointer;
}

.nu-icon:hover {
  transform: rotate(45deg);
  display: inline-block;
  transition: .2s;
}

.nu-icon:hover .n {
  transform: scale(-1) translateY(-5px);
  display: inline-block;
  transition: .2s;
}

.nu-icon .n {
  transition: .2s;
}

.nu-icon:hover .u {
  transform: translateY(-5px);
  display: inline-block;
  transition: .2s;
}

.nu-icon .u {
  transition: .2s;
  display: inline-block;

}


.web-content {
  background-color: var(--nu-background-primary);
}



/* Menu.css */

.menu-container {
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  transition: all 0.3s ease-in-out;
}

.menu-items {
  margin-left: auto;
}

.menu-container .active {
  text-decoration: underline;
}

.selected {
  background-image: var(--nu-primary-color);
  background-size: 200% 100%;
  background-position: -100%;
  display: inline-block;
  padding: 5px 0;
  position: relative;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  transition: all 0.3s ease-in-out;
}

/* Para dispositivos móviles */
@media (max-width: 768px) {
  .menu-container {
    position: fixed;
    bottom: 0;
    background-color: #000;
    /* Fondo del menú en móvil */
  }

  .menu-items {
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 10px;
    background-color: #333;
    /* Fondo del menú en móvil */
  }

  .menu-items a {
    padding: 10px;
  }
}

#menu_item a {
  background-image: linear-gradient(to right,
      var(--nu-primary-color),
      var(--nu-primary-color) 50%,
      #fff 50%);
  background-size: 200% 100%;
  background-position: -100%;
  display: inline-block;
  padding: 5px 0;
  position: relative;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  transition: all 0.3s ease-in-out;
}

#menu_item a:before {
  content: '';
  background: var(--nu-primary-color);
  display: block;
  position: absolute;
  bottom: -3px;
  left: 0;
  width: 0;
  height: 3px;
  transition: all 0.3s ease-in-out;
}

#menu_item a:hover {
  background-position: 0;
}

#menu_item a:hover::before {
  width: 100%;
}

#navbar_mobile {
  text-decoration: none;
  z-index: 3;
}





.home {
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow-x: hidden;
  position: relative;
  background: linear-gradient(0, var(--nu-background-primary), transparent, transparent, transparent, transparent, transparent);
  transition: .3s;
}

.home_title {
  font-size: 10vw;
  line-height: 0.8;
}

.home_title b {
  padding: 0;
  margin: 0;
}

.home-footer {
  width: 100%;
  display: flex;
  justify-content: start;
  align-items: center;
  text-align: start;
}

.home-footer h4 {
  white-space: nowrap;
  margin: 0 1rem 0 0;
  color: #fff;
}


.line {
  width: 75%;
  margin: 2rem;
  border: 1px solid white;
  height: 0;
}

.shrink {
  transition: transform 0.1s ease-out, opacity 0.1s ease-out;
  transform-origin: center;
}



/* Fija el fondo y evita que se desplace al hacer scroll */
.background {
  position: fixed;
  /* Mantiene el fondo en su lugar */
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: -1;
  /* Lo envía detrás del contenido */
  display: flex;
  justify-content: center;
  align-items: center;
}

.content {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(12deg, rgba(0, 0, 0, 0.8500) 36.00%, rgba(0, 0, 0, 0.8465) 40.58%, rgba(0, 0, 0, 0.8361) 45.17%, rgba(0, 0, 0, 0.8187) 49.75%, rgba(0, 0, 0, 0.7944) 54.33%, rgba(0, 0, 0, 0.7632) 58.92%, rgba(0, 0, 0, 0.7250) 63.50%, rgba(0, 0, 0, 0.6868) 68.08%, rgba(0, 0, 0, 0.6556) 72.67%, rgba(0, 0, 0, 0.6313) 77.25%, rgba(0, 0, 0, 0.6139) 81.83%, rgba(0, 0, 0, 0.6035) 86.42%, rgba(0, 0, 0, 0.6000) 91.00%);
  border-radius: 8px;
  z-index: 1;
}

.download-text {
  display: flex;
  align-items: center
}

ion-icon {
  color: azure;
  padding: .4rem;
  font-weight: bold;
}



.card-container {
  margin-top: 5rem;
  display: flex;
  align-items: center;
  flex-direction: column;
  overflow: hidden;
  width: 100%;
  height: 100vh;
  background: #1c1c1c;
}

.card {
  border-radius: 8px;
  margin: 0 8px;
  width: 10rem;
  border: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: transparent;
  color: rgb(233, 233, 233);
}

#event .image {
  width: 100%;
  height: 15rem;
  border-radius: 8px;
  object-fit: cover;
}

#place .image {
  width: 100%;
  height: 10rem;
  border-radius: 8px;
  object-fit: cover;
}

.info {
  display: flex;
  align-items: start;
  flex-direction: column;
  width: 100%;
}

.info .name-container {
  display: flex;
  align-items: start;
  width: 10rem;
}

.info .name {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-size: 0.8rem;
  margin: 0;
  margin-top: 10px;
  font-weight: 700;
}

.type {
  margin: 0;
  font-size: 0.7rem;
}

.distance {
  width: 100%;
  display: flex;
  justify-content: end;
  font-size: 0.8rem;
  font-weight: 700;
}


.card-wrapper {
  display: flex;
  animation: scrollX 40s linear infinite;
}

.card-wrapper-inverse {
  display: flex;
  animation: scrollXinverse 40s linear infinite;
}


@keyframes scrollXinverse {
  from {
    transform: translateX(-100vh);
  }

  to {
    transform: translateX(0%);
  }
}

@keyframes scrollX {
  from {
    transform: translateX(0%);
  }

  to {
    transform: translateX(-100vh);
  }
}





/* Contenedor principal */
.first-section {
  width: 100vw;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #0e100f;
  /* Fondo oscuro opcional */
  padding: 20px;
  position: relative;
}

/* Contenedor interno */
.first-inner-section {
  margin: auto;
  /* padding: 20px; */
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  height: 100%;
}

/* Columna de texto */
.first-text-section {
  flex: 1;
  min-width: 300px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 15px;
  /* padding: 20px; */
}

/* Títulos y textos */
.first-section-tag-1 {
  position: relative;
  z-index: 2;
  /* Mayor valor para estar por encima */
  transform: translateY(-7rem);
  border: 5px solid var(--nu-background-light);
  background: var(--nu-background-light);
  border-radius: 8px;
  font-weight: bold;
  width: fit-content;
  color: rgb(16, 16, 16);
  margin: 0;
  filter: drop-shadow(15px 15px 0px rgba(21, 21, 21, 0.32));
}

/* Títulos y textos */
.first-section-tag-2 {
  position: relative;
  z-index: 1;
  /* Menor valor para estar detrás */
  transform: translateY(-7rem) translateX(8rem);
  padding: 0.5rem 1rem;
  background: linear-gradient(45deg, var(--nu-primary-color), var(--nu-primary-primary-light));
  border-radius: 8px;
  color: rgb(16, 16, 16);
  margin: 0;
  width: fit-content;
}

.first-section-tag-3 {
  position: relative;
  z-index: 2;
  filter: drop-shadow(15px 15px 0px rgba(21, 21, 21, 0.32));

  /* Menor valor para estar detrás */
  transform: translateY(-9rem) translateX(20rem);
  padding: 0.5rem 1rem;
  background: var(--nu-background-light);
  border-radius: 8px;
  color: rgb(16, 16, 16);
  margin: 0;
  font-weight: bold;
  font-size: 2rem;
  width: fit-content;

}


.first-section-tag-4 {
  position: relative;
  z-index: 1;
  /* Menor valor para estar detrás */
  transform: translateY(-9rem) translateX(8rem);
  padding: 0.5rem 1rem;
  background: linear-gradient(45deg, var(--nu-primary-color), var(--nu-primary-primary-light));
  border-radius: 8px;
  color: rgb(16, 16, 16);
  margin: 0;
  width: fit-content;

}

.first-section-description {
  color: white;
  text-align: end;
  max-width: 30rem;
}

/* Animación de texto */
.custom-text-animation {
  animation: fadeIn 1.2s ease-in-out;
}

/* Contenedor de imagen */
.custom-image-container {
  flex: 1;
  min-width: 300px;
  display: flex;
  justify-content: center;
  padding: 20px;
}

.custom-image-container img {
  max-width: 100%;
  height: auto;
  transition: transform 0.3s ease-in-out;
}

.custom-image-container img:hover {
  transform: scale(1.05);
}



/* Contenedor principal */
.second-section {
  width: fit-content;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  position: relative;
}


/* Clase base común para todos los tags */
.options-tag {
  position: absolute;
  z-index: 1;
  padding: 0.5rem 1rem;
  border-radius: 8px;
  color: rgb(16, 16, 16);
  margin: 0;
  font-size: 1.2rem;
  font-weight: bold;
  text-align: center;
  opacity: 0;
  /* Inicialmente invisibles */
}

/* Estilos específicos para cada tag */
.options-tag-1 {
  transform: translateY(-9rem) translateX(-35rem);
  background: linear-gradient(45deg, var(--nu-primary-color), var(--nu-primary-primary-light));
}

.options-tag-2 {
  transform: translateY(-5rem) translateX(-12rem);
  background: linear-gradient(45deg, #FF6B6B, #FFE66D);
}

.options-tag-3 {
  transform: translateY(20rem) translateX(-10rem);
  background: linear-gradient(45deg, #4ECDC4, #84679d);
}

.options-tag-4 {
  transform: translateY(6rem) translateX(15rem);
  background: linear-gradient(45deg, #bb3e4b, #f38282);
}

.options-tag-5 {
  transform: translateY(-10rem) translateX(-5rem);
  background: linear-gradient(45deg, #7a67a9, #9dcb58);
}

.svg-circle-animation {
  color: red;
  position: absolute;
  left: 0;
}

.text-explain {
  font-size: 7rem;
}


.third-section {
  width: 100vw;
  display: flex;
  align-items: end;
}


.left-section-3 {
  background-color: var(--nu-primary-color);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  width: 50%;
  height: 100%;
}

.chat-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
  padding: 20px;
  border-radius: 10px;
  height: 100%;
  width: 100%;
  overflow-y: auto;
  background-color: var(--nu-primary-color);
}

.chat-message {
  max-width: 80%;
  padding: 10px;
  border-radius: 10px;
  position: relative;
}

.chat-message.left {
  text-align: start;
  align-self: flex-start;
  border: 5px solid var(--nu-background-light);
  background: var(--nu-background-light);
  border-radius: 8px;
  font-weight: bold;
  font-size: 1.5rem;
  width: fit-content;
  color: rgb(16, 16, 16);
  margin: 0;
  filter: drop-shadow(15px 15px 0px rgba(21, 21, 21, 0.32));
}



.chat-message.right {
  text-align: end;
  align-self: flex-end;
  border: 5px solid var(--nu-background-primary);
  border-radius: 8px;
  font-weight: bold;
  font-size: 1.5rem;
  width: fit-content;
  color: rgb(16, 16, 16);
  margin: 0;
  filter: drop-shadow(15px 15px 0px rgba(21, 21, 21, 0.32));
  background-color: var(--nu-background-primary);
  color: white;
}

.sender {
  font-weight: bold;
  margin-bottom: 5px;
  display: block;
}

.text {
  margin: 0;
}


.right-section-3 {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 50%;
  height: 100%;
}


.word-carousel {
  text-align: center;
  width: 100%;
  /* Ocupa todo el ancho disponible */
  font-size: clamp(1rem, 10vw, 4rem);
  /* Tamaño de fuente responsive */
  /* Evita que el texto se divida en varias líneas */
  overflow: hidden;
  /* Evita que el texto se desborde */
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 1rem;
}

/* Media query para móviles y tablets */
@media (max-width: 768px) {
  .first-section-tag-1 {
    transform: translate(-30px, 0px);
    font-size: .8rem !important;
    filter: drop-shadow(5px 5px 0px rgba(21, 21, 21, 0.32));
  }

  .first-section-tag-2 {
    transform: translate(30px, 0px);
    font-size: .9rem !important;
    filter: drop-shadow(5px 5px 0px rgba(21, 21, 21, 0.32));
  }

  .first-section-description {
    text-align: center;
    font-size: 1rem;
    margin-top: 8rem;
    padding: 1rem;
  }


  .home_title {
    font-size: 20vw;
  }

  .chat-message.left {
    font-size: 1rem;
    /* Tamaño de fuente más pequeño para móviles */
  }

  .chat-message.right {
    font-size: 1rem;
    /* Tamaño de fuente más pequeño para móviles */
  }

  .word-carousel h2 {
    font-size: 1.25rem;
  }

  .word-carousel h5 {
    font-size: .75rem;
  }
}



/* Animaciones */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Media queries para mejorar responsividad */
@media (max-width: 768px) {
  .custom-inner-container {
    flex-direction: column;
    text-align: center;
  }

  .custom-text-container {
    align-items: center;
  }

  .custom-description {
    text-align: center;
  }
}




.custom-spotlight-card {
  overflow: hidden;
  position: relative;
  cursor: default;
}

.custom-spotlight-card .title {
  font-size: 3rem;
}

.custom-spotlight-card .description {
  font-size: 1.5rem;
}

.custom-spotlight-card .icon {
  font-size: 10rem;
  position: absolute;
  bottom: 0;
  right: 0;
  transform: translate(50px, 50px);
}

/* Contenedor del slider */
.slider-container {
  width: 100%;
  overflow: hidden;
  position: relative;
}

/* Slider */
.slider {
  display: flex;
  transition: transform 0.5s ease-in-out;
}

/* Cada slide */
.slide {
  min-width: 100%;
  box-sizing: border-box;
}

/* Indicadores */
.slider-indicators {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.indicator {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #ccc;
  margin: 0 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.indicator.active {
  background-color: #fff;
}


@media (max-width: 768px) {
  #cards_section .title {
    font-size: 2rem;
  }

  .custom-spotlight-card .title {
    font-size: 2rem;
  }

  .custom-spotlight-card .description {
    z-index: 2;
    position: relative;
    font-size: 1.2rem;
  }

  .custom-spotlight-card .icon {
    transform: translate(50px, 50px);
  }
}





/* Estilos generales para el contenedor de contacto */
.contact {
  background-image: url('../public/imgs/contact/contactbg.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  height: 100vh;
  background-color: var(--nu-background-primary);
  background-image: linear-gradient(transparent, transparent, black);
  text-align: center;
}

.title {
  font-size: 2.5rem;
  margin-bottom: 20px;
}

.contact-description {
  font-size: 1.2rem;
  margin-bottom: 30px;
  max-width: 55rem;
}

.contact-form {
  width: 100%;
  max-width: 500px;
  text-decoration: none;
}

.btn-submit {
  background: #c6ff36;
  /* Verde neón */
  border: none;
  border-radius: 20px;
  padding: 8px 15px;
  font-weight: bold;
  cursor: pointer;
  transition: 0.3s ease;
  margin-left: 10px;
  color: var(--nu-background-primary);
  text-decoration: none;
}

.btn-submit:hover {
  background: #6f9120;
}


footer {
  background-color: #000;
  position: relative;
  /* Necesario para posicionar la watermark dentro */
  text-align: center;
  padding: 50px 0;
  overflow: hidden;
  /* Asegura que nada sobresalga del footer */
}

.footer-watermark {
  font-size: 10rem;
  color: white;
  opacity: 0.2;
  position: absolute;
  width: 100%;
  bottom: -7rem;
  /* Mueve la mitad del texto hacia afuera */
  left: 50%;
  transform: translateX(-50%);
  white-space: nowrap;
  /* Evita que se divida en varias líneas */
  overflow: hidden;
  /* Asegura que solo se vea la parte superior */
}

.footer-watermark .primaryColor {
  color: white;
}

@media (max-width: 768px) {
  .footer-watermark {
    font-size: 7rem;
  }
}