.legal {
    color: white;
    padding: 10rem;
    text-align: start;
}

.legal a {
    text-decoration: none;
    font-weight: bold;
    color: var(--nu-primary-color);
}

@media (max-width: 768px) {
    .legal {
        color: white;
        padding: 2rem;
        text-align: start;
    }
}  