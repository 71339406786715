#profile {
    display: flex;
    flex-direction: column;

}

#profile .image {
    border-radius: 100rem;
    width: 6rem;
    height: 6rem;
    border: 3px solid #1c1c1c;
    outline: 4px solid white;
    object-fit: cover;
}

.name {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    font-size: 0.8rem;
    margin: 0;
    margin-top: 10px;
    font-weight: 700;
}

