.note-section {
    text-align: center;
  }
  
  .note-card {
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .note-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  }
  
  .note-title {
    font-size: 1.5rem;
    font-weight: bold;
    color: #d0d0d0;
  }
  
  .note-content {
    color: #d0d0d0;
    font-weight: 500;
  }
  
  .note-img {
    transition: .5s all ease;
    height: 40vh;
  }

  .note-card-pc {
    perspective: 1000px;
  }

  .note-card-pc .note-content{
    transition: transform 0.6s ease-in-out, opacity 0.6s ease-in-out;
    transform-origin: bottom;
    display: inline-block;  
  }
  
  .note-card-pc:hover .note-img {
    animation: translateThenScale 1s forwards; 
  }

  .note-card-pc:hover .note-content {
    transform: rotateX(-90deg);
    opacity: 0;
  }

  .cube-container {
    perspective: 1000px;
    display: inline-block;
  }
  
  .cube-container:hover .cube-text {
    transform: rotateX(90deg);
    opacity: 0;
  }
  

.toggle-switch {
  display: flex;
  background-color: #1c1c1c;
  max-width: 30rem;
  border-radius: 10rem;
  justify-content: center;
  cursor: pointer;
  font-weight: 600;
}

.toggle-switch .item{
  padding: .5rem;
}

.toggle-switch .active{
  background-color: #333;
  border-radius: 10rem;
}

.fade-enter {
  opacity: 0;
}
.fade-enter-active {
  opacity: 1;
  transition: opacity 900ms;
}
.fade-exit {
  opacity: 1;
}
.fade-exit-active {
  opacity: 0;
  transition: opacity 500ms;
}

.btn-primary {
  background: #c6ff36; /* Verde neón */
  border: none;
  border-radius: 20px;
  padding: 8px 15px;
  font-weight: bold;
  cursor: pointer;
  transition: 0.3s ease;
  margin-left: 10px;
  color:#1a1a1a;
}

.btn-primary:hover {
  background: #6f9120;
}




@keyframes translateThenScale {
  0% {
    transform: translateY(0) scale(1);
  }
  50% {
    transform: translateY(3.5rem) scale(1);
  }
  100% {
    transform: translateY(4rem) scale(1.8);
    z-index: 1;
  }
}
