.text-rotate {
    display: flex;
    flex-wrap: wrap;
    white-space: pre-wrap;
    position: relative;
    color: #C6FF28;
  }
  
  .text-rotate-sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border: 0;
  }
  
  .text-rotate-word {
    display: inline-flex;
  }
  
  .text-rotate-lines {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  
  .text-rotate-element {
    display: inline-block;
  }
  
  .text-rotate-space {
    white-space: pre;
  }